import { AApi } from "@/service/AApi";
const API = new AApi(
  { url: "kpi" },
  {
    kpiexport: {
      async request(data) {
        return this.sdk({
          url: "/kpi-export",
          responseType: "blob",
          params: data
        });
      },
    },
    kpitemplace: {
      async request() {
        return this.sdk({
          url: "/kpitemplace",
          responseType: "blob",
        });
      },
    },
    importkpi: {
      async request(data) {
        return this.sdk({
          url: "/importkpi",
          method: "post",
          data,
        });
      },
      type: "create",
      option: {
        handleError: "Lỗi import",
        handleSuccess: "Thêm dữ liệu thành công"
      }
    },
    updateThucHien: {
      async request(id) {
        return this.sdk({
          url: `/kpi-update-thuc-hien/${id}`,
          method: "put",
        });
      },
    },
  }
);

// export function show(id, params) {
//   return request({
//     url: `/kpi/${id}`,
//     method: "get",
//     params,
//   });
// }
// export function store(data) {
//   return request({
//     url: `/kpi`,
//     method: "post",
//     data,
//   });
// }
// export function update(id, data) {
//   return request({
//     url: `/kpi/${id}`,
//     method: "put",
//     data,
//   });
// }

// export function destroy(id) {
//   return request({
//     url: `/kpi/${id}`,
//     method: "delete",
//   });
// }
export default API;
