<template>
  <PageListLayout
    :title="`${data.nguoi_phu_trach.name} - ${data.khach_hang.name}`"
    linkBack="/quanlykpi"
    useBack
    noFilter
  >
    <v-layout column>
      <div style="font-size: 18px; color: #0d47a1; font-weight: bold">
        KPI Đăng ký
      </div>
      <v-layout>
        <v-card
          class="mt-4 mr-4"
          width="400"
          outlined
          v-for="(item, index) in keys"
          :key="`ke_hoach_${index}`"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4 text-uppercase">{{ item.text }}</div>
              <v-list-item-title class="headline mb-1">
                <div class="d-flex pr-1">
                  <div class="text-left flex-grow-1 d-flex align-center">
                    <span v-if="editKeHoach != item.key">
                      {{
                        (data.ke_hoach &&
                          formatnumber(data.ke_hoach[item.key])) ||
                        "--"
                      }}
                    </span>
                    <v-text-field
                      class="pt-0"
                      hide-details
                      v-else
                      v-model="form.ke_hoach[item.key]"
                      autofocus
                    >
                    </v-text-field>
                  </div>
                  <v-btn
                    icon
                    v-if="editKeHoach != item.key"
                    @click="setEdit(item.key, 'ke_hoach')"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <div v-else>
                    <v-btn icon @click="save">
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                    <v-btn icon @click="cancel('ke_hoach')">
                      <v-icon>mdi-cancel</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.description }}</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-avatar
              tile
              size="80"
              color="grey"
            ></v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-layout>

      <div
        class="mt-8 d-flex align-center"
        style="font-size: 18px; color: #0d47a1; font-weight: bold"
      >
        KPI Thực hiện
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="ml-1"
              :loading="loadingUpdate"
              icon
              v-on="on"
              @click="updateKpi()"
            >
              <v-icon>mdi-update</v-icon>
            </v-btn>
          </template>
          <span>Cập nhật thực hiện Kpi</span>
        </v-tooltip>
      </div>
      <v-layout>
        <v-card
          class="mt-4 mr-4"
          width="400"
          outlined
          v-for="(item, index) in keys"
          :key="`thuc_hien_${index}`"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4 text-uppercase">{{ item.text }}</div>
              <v-list-item-title class="headline mb-1">
                <div class="d-flex pr-1">
                  <div class="text-left flex-grow-1 d-flex align-center">
                    <span v-if="editThucHien != item.key">
                      {{
                        (data.thuc_hien &&
                          formatnumber(data.thuc_hien[item.key])) ||
                        "--"
                      }}
                    </span>
                    <v-text-field
                      class="pt-0"
                      hide-details
                      v-else
                      v-model="form.thuc_hien[item.key]"
                      autofocus
                    >
                    </v-text-field>
                  </div>
                  <v-btn
                    icon
                    v-if="false"
                    @click="setEdit(item.key, 'thuc_hien')"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <!-- <div v-else>
                    <v-btn icon @click="save">
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                    <v-btn icon @click="cancel('thuc_hien')">
                      <v-icon>mdi-cancel</v-icon>
                    </v-btn>
                  </div> -->
                </div>
                <div>
                  <v-icon :color="item.color">{{
                    `mdi-arrow-${
                      data.percent[item.key] > 0 ? "top" : "bottom"
                    }-right`
                  }}</v-icon>

                  <span style="font-size: 15px"
                    ><b :style="`color: ${data.percent[item.key] > 0 ? 'green' : 'red'}`">{{
                      `${data.percent[item.key]}%`
                    }}</b></span
                  >
                </div>
              </v-list-item-title>
            </v-list-item-content>
            <v-card :color="item.color" dark width="150">
              <v-card-text class="pa-2">
                <v-sheet color="rgba(0, 0, 0, .12)">
                  <v-sparkline
                    :value="value"
                    color="rgba(255, 255, 255, .7)"
                    height="100"
                    stroke-linecap="round"
                    smooth
                  >
                    <template v-slot:label="item"> ${{ item.value }} </template>
                  </v-sparkline>
                </v-sheet>
              </v-card-text>
            </v-card>
          </v-list-item>
          <!-- <v-card-actions>
            <v-btn outlined rounded text> Cập nhật </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-layout>
    </v-layout>
  </PageListLayout>
</template>

<script>
import KPI from "@/api/kpi.api";
import { numberFormat } from "@/utils/moneyFormat";
export default {
  mounted() {
    this.fetchData();
  },
  data: () => ({
    data: {
      thuc_hien: {},
      ke_hoach: {},
    },
    form: {
      thuc_hien: { phe_lieu: null, hang_ngoai: null, xu_ly: null },
      ke_hoach: { phe_lieu: null, hang_ngoai: null, xu_ly: null },
    },
    value: [423, 446, 675, 510, 590, 610, 760],
    keys: [
      {
        key: "phe_lieu",
        text: "Phế liệu",
        description: "Tăng số lượng thu gom từ khách hàng",
        color: "pink",
      },
      {
        key: "hang_ngoai",
        text: "Hàng ngoài",
        description: "Thu gom từ bên ngoài",
        color: "orange",
      },
      {
        key: "xu_ly",
        text: "Xử lý",
        description: "Xử lý",
        color: "green",
      },
    ],
    editThucHien: null,
    editKeHoach: null,
    loadingUpdate: false,
  }),
  methods: {
    async fetchData(params = {}) {
      const res = await KPI.getDetail(this.$route.params.id, params);
      console.log("🚀 ~ file: show.vue ~ line 223 ~ fetchData ~ res", res)
      this.data = res;
      if (res.thuc_hien) {
        this.form.thuc_hien = { ...this.form.thuc_hien, ...res.thuc_hien };
      }
      if (res.ke_hoach) {
        this.form.ke_hoach = { ...this.form.ke_hoach, ...res.ke_hoach };
      }
      this.data.percent = {};
      ["phe_lieu", "hang_ngoai", "xu_ly"].forEach((key) => {
        this.data.percent[key] = this.handlerPercent(key);
      });
    },
    formatnumber(number) {
      return numberFormat(number);
    },
    cancel() {
      this.editThucHien = null;
      this.editKeHoach = null;
    },
    handlerPercent(key) {
      return this.form.thuc_hien[key] && this.form.ke_hoach[key]
        ? (
            parseFloat(this.form.thuc_hien[key] * 100) / this.form.ke_hoach[key]
          ).toFixed(2)
        : "__";
    },
    async save() {
      try {
        const res = await KPI.update(this.data.id, this.form);
        this.fetchData();
      } finally {
        this.cancel();
      }
    },
    async updateKpi() {
      try {
        this.loadingUpdate = true;
        const res = await KPI.updateThucHien(this.$route.params.id);
        this.$toast.info(`Cập nhật thành công`, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
        this.fetchData();
      } finally {
        this.loadingUpdate = false;
      }
    },
    setEdit(key, type) {
      if (type == "thuc_hien") {
        this.editThucHien = key;
        this.editKeHoach = null;
      } else if (type == "ke_hoach") {
        this.editKeHoach = key;
        this.editThucHien = null;
      }
    },
  },
};
</script>

<style>
</style>